import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';

import { BootstrapRoutingModule } from './bootstrap-routing.module';
import { BootstrapComponent } from './bootstrap.component';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MsalGuard, MsalInterceptor, MsalModule, MsalService, MsalRedirectComponent, MsalBroadcastService } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel, EventType, EventMessage, AuthenticationResult } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

import { UnauthorizedComponent } from '../unauthorized/unauthorized.component';

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
}
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
const protectedResourceMap = new Map<string, Array<string>>();
protectedResourceMap.set(GRAPH_ENDPOINT, ['user.read']);
protectedResourceMap.set(environment.apiUri, ['api://5f0c91b4-9430-42dd-bd4a-5cf92a9f43b0/user_impersonation']);

@NgModule({
  declarations: [BootstrapComponent, UnauthorizedComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BootstrapRoutingModule,
    FlexLayoutModule,
    MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
        auth: environment.auth,
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11
        },
        system: {
            loggerOptions: {
                loggerCallback: loggerCallback,
                piiLoggingEnabled: false,
                logLevel: LogLevel.Verbose
            }
        }
    }),
    {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
            scopes: ['user.read']
        },
        //loginFailedRoute: "/"
    },
    {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap
    }),
  ],
  providers: [
    {
        provide: APP_INITIALIZER,
        useFactory: appInitializerFactory,
        deps: [MsalService, HttpClient],
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
    },
    MsalGuard
  ],
  bootstrap: [BootstrapComponent, MsalRedirectComponent],
})
export class BootstrapModule {}




// Initialize application with preset language, content will not be displayed until the translation file required by ngx-translate is
// fully loaded, for more details @see https://github.com/ngx-translate/core/issues/517#issuecomment-299637956
export function appInitializerFactory(
  authService: MsalService,
  http: HttpClient
): any {
  return async () => {
    console.log('Login user');
    let msalInstance = authService.instance;
    //attempt to retrieve account
    let accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }
    let result = await msalInstance.handleRedirectPromise();
    console.log('handle redirect promise', result);
    if(!result) {
      //no redirect promise - no interaction or just started
      let account = msalInstance.getActiveAccount();
      console.log('get active account', account);
      if(!account){
        // redirect anonymous user to login page
        await msalInstance.loginRedirect();
      } else {
        //try logging in with current credentials
        try {
          let resp = await msalInstance.acquireTokenSilent({
            scopes: ['user.read']
          });
          console.log('Credentials are valid', resp);
        } catch(e) {
          console.error(e);
          await msalInstance.loginRedirect();
        }
      }
    } else {
      //attempt to retrieve account again after handling a successfull redirect promise
      accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
      }
    }
    //Finally, retrieve user profile
    const profile = await http.get(GRAPH_ENDPOINT).toPromise();;
    console.log('profile', profile);
  };
}


export function isUserAdmin(authService: MsalService) {
  let msalInstance = authService.instance;
  let account = msalInstance.getActiveAccount();

  if (account && account.idTokenClaims.roles) {
    const roles = account.idTokenClaims.roles;
    return roles.includes('Admin') ? 1 : 0;
  }

  return 0;
}
