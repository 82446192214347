import { Component, Renderer2 } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class BootstrapComponent {
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private router: Router,
    private renderer: Renderer2,
    private msalBroadcastService: MsalBroadcastService) {
        const subscription = this.router.events.subscribe(
            value => {
                if(value instanceof NavigationEnd) {
                    let loader = this.renderer.selectRootElement('#loader');
                    this.renderer.setStyle(loader, 'display', 'none');
                    subscription.unsubscribe();
                  }
            }
        );
    }

    ngOnInit(): void {
      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntil(this._destroying$)
        )
        .subscribe(() => {
          // Do user account/UI functions here
        })
    }
}