import {InjectionToken, NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import { UnauthorizedComponent } from '../unauthorized/unauthorized.component';

import { MsalGuard } from '@azure/msal-angular';

const lazyPathValue = 'app';
export const LAZY_PATH = new InjectionToken<String>('LAZY_PATH');
const routes: Routes = [
  {
    path: lazyPathValue,
    //canLoad: [MsalGuard],
    loadChildren: () => import('../app.module').then(mod => mod.AppModule) 
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: lazyPathValue
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, 
    {
    preloadingStrategy: PreloadAllModules,
    // this allows #-hash-fragment scrolling. Removing the #-hash after the OAuth-redirect is required with this configuration
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
}
  )],
  //provide non class dependency
  providers: [{ provide: LAZY_PATH, useValue: lazyPathValue }],
  exports: [RouterModule],
})
export class BootstrapRoutingModule {}