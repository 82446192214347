import {IEnvironment} from './IEnvironment';

export const environment: IEnvironment = {
  production: false,
  auth: {
    clientId: '5f0c91b4-9430-42dd-bd4a-5cf92a9f43b0',
    authority: 'https://login.microsoftonline.com/8d4b558f-7b2e-40ba-ad1f-e04d79e6265a',
    redirectUri: 'https://dev.finops.continental.cloud/',
    postLogoutRedirectUri: '/'
  },
  apiUri: 'https://api.dev.finops.continental.cloud'
};
